<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <section :class="`px-4 pt-6 pb-1 md:px-6 md:pt-12 ${bgClass}`">
    <div class="mx-auto max-w-6xl">
      <h2
        class="pb-6 pt-3 text-center text-2xl text-dynamic-primary md:pb-9 md:pt-0 md:text-3xl"
      >
        {{ block.heading }}
      </h2>
      <div class="w-full">
        <BannerSwiperPrimary />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const bgClass = props?.block?.backgroundColor
  ? props.block.backgroundColor
  : 'bg-white';
</script>
